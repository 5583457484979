import http from "@/plugins/https";
import apiRoutes from "@/router/daf-routes";
import apiRoutesRh from "@/router/rh-routes";
import moment from "moment";
import axios from "axios";
import store from "@/store/store";
const allFunctions = {
  /* GET ALL DAF PRESTATAIRES */
  async getAllPrestataire() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getSuppliers, {
        params: {
          page: 1,
          limit: 1000,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET DAF SUPPLIER BY ID */
  async getSupplierById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getSupplierById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET BUDGET LINE */
  async getAllBudget() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getBudgets, {
        params: {
          page: 1,
          limit: 1000,
        },
      })
      .then((response) => {
        // console.log(response)
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET BUDGET LINE OR PAYMENT SLIP */
  async getBudgetLineOrPaymentSlip(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getBudgetLineOrPaymentSlip, {
        params: {
          budgetId: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* GET BUDGET STATS */
  async getBudgetStat(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getBudgetStats, {
        params: {
          budgetId: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE NEW MARCHE */
  async createNewMarche(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createMarket, {
        author: data.author,
        name: data.name,
        type: data.type,
        reference: data.reference,
        meta: data.meta,
        startDate: data.dateBegin,
        duration: data.duration,
        amount: data.amount,
        taxe: data.taxe,
        taxePercentage: data.taxePercentage,
        budgetId: data.sourceFinance,
        suppliersIds: data.suppliers,
        selectedSupplierId: data.selectedSupplier,
        budgetLineId: data.budgetLineId,
        paiementSlipId: data.paymentSlipId,
        marketPaiementType: data.marketPaiementType,
        paiamentDeadline: data.paiamentDeadline,
        selectedSupplierJustifyFiles: data.files,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL MARKET */
  async getAllMarket() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllMarket, {
        params: {
          term: "",
          page: 1,
          limit: 1000,
        },
      })
      .then((response) => {
        console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET MARKET BY ID */
  async getMarketBYId(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getMarketById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  formatNumber(number) {
    return new Intl.NumberFormat("fr").format(number);
  },

  /* CREATE NEW FACTURE */
  async createNewFacture(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createExpense, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL FACTURES */
  async getAllFactures(type, state) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllExpenses, {
        params: {
          state: state,
          type: type,
          page: 1,
          limit: 1000,
        },
      })
      .then((response) => {
        console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET EXPENSE BY ID */
  async getExpenseById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getExpenseById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  formatDate(date) {
    moment.locale("FR");
    return moment(date).format("DD MMM YYYY");
  },

  /* PAID EXPENSE */
  async paidExpense(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.paisExpense, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* BUDGET LINE BY ID */
  async getOneBudgetLine(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getOneBudgetLine, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL MISSION */
  async getAllMissions(state, type) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllMission, {
        params: {
          state: state,
          type: type,
          page: 1,
          limit: 1000,
        },
      })
      .then((response) => {
        console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL MISSION GROUP */
  async getAllMissionGroup(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllMissionGroup, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PAYMENTS STATS */
  async getPaymentStats() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPaymentStats)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET EXPENSES STATS */
  async getExpenseStats() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getExpenseStats)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CLOSE MISSION GROUP */
  async closeMissionGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.closeMissionGroup, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD NEW WALLET */
  async addNewWallet(id) {
    return await http.post(apiRoutes.baseURL + apiRoutes.createWallet, {
      supplierId: id,
    });
  },

  /* CREATE ALL RH AGENT STATS */
  async createAgentStats(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createAgentStats, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL WALLET */
  async getAllWallet() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllWallet, {
        params: {
          page: 1,
          limit: 1000,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE  RH EVOLUTION STATS */
  async createEvolutionStats(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createEvolutionStats, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE STATE */
  async updateExpenseState(data) {
    let result = null;
    await http
      .put(apiRoutes.baseURL + apiRoutes.updateExpenseState, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* PAID MISSION */
  async paidMission(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.paidMission, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET WALLET BY ID */
  async getWalletById(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.getWalletById, {
      params: data,
    });
  },

  /* CREATE FUND WALLET */
  async createFundWallet(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createFundWallet, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPLOAD FILE */
  async uploadFile(file) {
    let url = null;
    if (file !== null || file !== "") {
      let formData = new FormData();
      formData.append("file", file);

      await axios
        .post(apiRoutesRh.baseUrlFile + apiRoutesRh.addFile, formData, {
          headers: {
            "x-api-key": "a2f2db80-6d5a-4a95-adf3-6b66a778d48b",
          },
        })
        .then((response) => {
          // console.log(response);
          url = response.data.url;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return url;
  },

  /* GET WALLET FUNDING BY ID */
  async getWalletFundingById(id, startDate, endDate) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getWalletFundingById, {
        params: {
          id: id,
          startDate: startDate,
          endDate: endDate,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* CREATE NEW MARCHE */
  async addMarketFile(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.addMarketFile, {
        marketId: data.id,
        addedFiles: data.files,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* CREATE SURVEY */
  async createSurvey(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.createSurvey, data)
      .then((response) => {
        console.log(response);
        result.response = response;
      })
      .catch((error) => {
        // console.log(error);
        result.error = error;
      });
    return result;
  },

  /* CREATE RECIPIENT */
  async createRecipient(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createRecipient, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL RECIPIENTS */
  async getAllRecipients() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllRecipients)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE ONE RECIPIENT */
  async updateOneRecipient(data) {
    return await http.post(apiRoutes.baseURL + apiRoutes.updateRecipient, data);
  },

  /* CREATE INVOICE */
  async createInvoice(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.createInvoice, data)
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        console.log(error);
        result.error = error;
      });
    return result;
  },

  /* GET ALL FUNDING WALLET INVOICE */
  async getFundingWalletInvoice(data) {
    return await http.get(
      apiRoutes.baseURL + apiRoutes.getFundingWalletInvoice,
      {
        params: data,
      }
    );
  },

  /* GET DAF STATS */
  async getDafStat() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getDafStats, {})
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL FUNDING WALLET SURVEY */
  async getFundingWalletSurvey(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.getSurvey, {
      params: data,
    });
  },

  /* UPDATE SUPPLIER */
  async updateSupplier(data) {
    let result = null;
    await http
      .put(apiRoutes.baseURL + apiRoutes.updateSupplier, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PAYROLL GROUP */
  async getPayrollGroup(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPayrollGroup, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PAYROLL ONE GROUP BY ID */
  async getPayrollGroupById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPayrollGroupById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* PAID ONE PAYROLL */
  async paidOnePayroll(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.paidPayroll, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL AGENTS */
  async getAllAgents() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllAgents)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CLOSE PAYROLL GROUP */
  async closePayrollGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.closePayrollGroup, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* GET DAF STATS */
  async getPayrollStat(year) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPayrollStats, {
        params: {
          year: year,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL DIVISIONS */
  async getAllDivisions(data) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAllDivisions, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE NEW DIVISION */
  async createDivision(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createDivision, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE DIVISIONS */
  async updateDivision(id, data) {
    let result = null;
    await http
      .patch(apiRoutesRh.baseURL + apiRoutesRh.updateDivision(id), data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL AGENTS */
  async getAllAgentsRh(data) {
    let result = null;
    const tab = {
      category: data.category,
      status: data.status,
      matricule: data.matricule,
      professionalEmail: data.professionalEmail,
      personalEmail: data.personalEmail,
      phone: data.phone,
      firstName: data.firstName,
      lastName: data.lastName,
      search: data.search,
      page: data.page,
      take: data.take,
    };
    if (tab.category === "") {
      delete tab["category"];
    }
    if (tab.status === "") {
      delete tab["status"];
    }
    if (tab.matricule === null) {
      delete tab["matricule"];
    }
    if (tab.professionalEmail === "") {
      delete tab["professionalEmail"];
    }
    if (tab.personalEmail === "") {
      delete tab["personalEmail"];
    }
    if (tab.phone === "") {
      delete tab["phone"];
    }
    if (tab.firstName === "") {
      delete tab["firstName"];
    }
    if (tab.lastName === "") {
      delete tab["lastName"];
    }
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAgent, {
        params: tab,
      })
      .then((response) => {
        // console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD DIVISION MEMBERS */
  async addDivisionMembers(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.addDivisionMember, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* REMOVE DIVISION MEMBER */
  async removeDivisionMember(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.removeDivisionMember, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL ADMINISTRATIVE ACTS */
  async getAllAdministrativeActs() {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAllActs)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* SET DIVISION MANAGER */
  async setDivisionManager(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.setDivisionManager, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE NEW ACT */
  async createAct(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createNewAct, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* APPROVE ACT */
  async approvedAct(id) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.approveAct(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* REJECT ACT */
  async rejectedAct(id) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.rejectAct(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL JOBS */
  async getJobs() {
    let result = null;
    await http
      .get(
        apiRoutes.baseURL + apiRoutes.getJobs
        //      {
        //     params: {
        //         page: 1,
        //         limit: 1000
        //     }
        // }
      )
      .then((response) => {
        console.log(this.result);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* GET ALL HOSPITAL */
  async getAllHospitalCenters(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.getAllHospitalCenters, {
      params: data,
    });
  },
  /* GET HOSPITAL BY ID */
  async getHospitalCentersById(data) {
    return await http.get(
      apiRoutes.baseURL + apiRoutes.getHospitalCentersById,
      {
        params: data,
      }
    );
  },
  /* CREATE NEW MARCHE */
  async createHospitalCenter(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createHospitalCenter, {
        name: data.name,
        location: data.location,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* CREATE FUND WALLET */
  async createReceipt(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createReceipt, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* CREATE LITIGATION */
  async createLitigation(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createLitigation, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* TREAT LITIGATION */
  async treatLitigation(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.treatLitigation, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL STRUCTURES */
  async getAllStructure(data) {
    let result = null;
    const tab = {
      type: data.type,
      name: data.name,
      id: data.id,
      parent: data.parent,
      search: data.search,
      searchType: data.searchType,
      page: data.page,
      take: data.take,
    };
    if (tab.type === "") {
      delete tab["type"];
    }
    if (tab.name === "") {
      delete tab["name"];
    }
    if (tab.id === null) {
      delete tab["id"];
    }
    if (tab.parent === "") {
      delete tab["parent"];
    }
    if (tab.search === "") {
      delete tab["search"];
    }
    if (tab.searchType === "") {
      delete tab["searchType"];
    }
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getStructure, {
        params: tab,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE NEW SERVICE */
  async createService(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createService, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET STRUCTURE BY ID */
  async getStructureById(id) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getStructureById(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE STRUCTURE BY ID */
  async updateStructureById(id, data) {
    let result = null;
    await http
      .patch(apiRoutesRh.baseURL + apiRoutesRh.updateStructureById(id), data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE ACT DOCUMENT */
  async generateActDocument(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.generateAct, data)
      .then((response) => {
        // console.log(response);
        result = response.result;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE RECRUITMENT */
  async createRecruitment(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createRecruitment, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL CORPS DE METIER */
  async getAllCorps(data) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getCorps, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE LITIGATION */
  async createFormation(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createFormation, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* GET ALL HOSPITAL */
  async getAllFormation() {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAllFormation, {
        params: {
          page: 1,
          limit: 10000,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL RECRUITMENTS */
  async getAllRecruitments(data) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getRecruitments, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PAYROLL REVERSEMENT */
  async getPayrollReversement(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPayrollReversement, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE PAYROLL REVERSEMENT */
  async updatePayrollReversement(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateTaxeReversment, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET business day */
  async extractWeek(debut, fin) {
    let start = new Date(debut);
    let end = new Date(fin);

    // initial total
    let totalBusinessDays = 0;

    // normalize both start and end to beginning of the day
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);

    let current = new Date(start);
    current.setDate(current.getDate() + 1);
    let day;

    // loop through each day, checking
    while (current <= end) {
      day = current.getDay();

      if (day >= 1 && day <= 5) {
        ++totalBusinessDays;
      }

      current.setDate(current.getDate() + 1);
    }

    totalBusinessDays = totalBusinessDays + 1;
    return totalBusinessDays;
  },
  /* GET ALL HOSPITAL */
  async getAllRetiring() {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAllRetiring, {
        params: {
          page: 1,
          take: 10000,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET AGENT BY ID */
  async getAgentByEmail(data) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAgent, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE ARCHIVE ACT */
  async createArchiveAct(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.archiveAct, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD DIVISION MEMBERS */
  async createTasks(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createTasks, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ARCHIVE ACT */
  async getArchiveAct(data) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getArchive, {
        params: data,
      })

      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ONE ACT BY ID */
  async getActById(id) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getActById(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET STRUCTURE BY ID
     async getAllTasksByAgentId(id) {
        let result = null;
        await http.get(apiRoutesRh.baseURL + apiRoutesRh.getAllTasksByAgentId(id))
            .then(response => {
                // console.log(response);
                result = response;
            }).catch(error => {
                console.log(error);
            })
        return result;
    },*/
  /* GET STRUCTURE BY ID */
  async getAllTasksByAgentId() {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAllTasksByAgentId)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* SET TASK STATUS BY ID */
  async setTaskStatus(id) {
    let result = null;
    const body = {
      status: "done",
    };
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.setTaskStatus + id, body)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* MAKE TO FILL RECRUITMENT */
  async validRecruitment(id) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.makeToFillRecruitment(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CANCEL RECRUITMENT */
  async cancelRecruitment(id) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.cancelRecruitment(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE PAYMENT RECEIPT DOCUMENT */
  async generatePaymentReceiptDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createPaymentReceiptDocument, {
        params: {
          payrollId: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE MISSION ETAT D'AVANCE DOCUMENT */
  async generateMissionAdvanceDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createMissionAdvanceEtatDocument, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE MISSION RECEIPT PAYMENT DOCUMENT */
  async generateMissionReceiptPaymentDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createMissionPaymentReceiptDocument, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE ADVANCE FUND SURVEY DOCUMENT */
  async generateAdvanceFundSurveyDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createAdvanceSurveyDocument, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE EXTERN MISSION PAYMENT DOCUMENT */
  async generateExternMissionPaymentDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createExternMissionPaymentReceipt, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE PAYROLL GROUP PAYMENT POINT DOCUMENT */
  async generatePayrollGroupPaymentPointDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createPayrollGroupPaymentPoint, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE BANK PAYROLL POINT DOCUMENT */
  async generateBankPayrollPointDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createBankPayrollPoint, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* SIMULATE SALARY CALCULATE */
  async simulateSalary(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.calculateItsCnss, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL -payroll-tax-config */
  async getAllPayrollTax() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllPayrollTaxConfig)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* DELETE PAYROLL ONE AGENT */
  async deletePayrollOneAgent(id) {
    let result = null;
    await http
      .delete(apiRoutes.baseURL + apiRoutes.deleteAgentPayroll, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CANCEL PAYROLL GROUP */
  async cancelPayrollGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.cancelPayrollGroup + data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE AGENT STATUS IN PAYROLL */
  async updateAgentStatusInPayroll(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.updatePayrollAgent, {
        params: {
          id: data,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE GLOBAL BANK POINT DOCUMENT */
  async generateGlobalBankPointDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createGlobalBankPoint, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE GLOBAL STRUCTURE POINT DOCUMENT */
  async generateGlobalStructurePointDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createGlobalStructurePoint, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PAYROLL BANK LISTS */
  async getPayrollBankFiles(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPayrollBankFile, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* SET PAYROLL BANK FILE */
  async setPayrollBankFile(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.setPayrollBankFile, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* CREATE  TAX */
  async createTax(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createOtherTax, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* GET ALL -payroll-tax-config */
  async getAllOtherlTax() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllOtherlTax)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL TAXES */
  async getAllTaxes() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllTaxes)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE CNSS REVERSEMENT DOCUMENT */
  async generateCnssDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createCnssSurveyDocument, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE ITS REVERSEMENT DOCUMENT */
  async generateItsDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createItsSurveyDocument, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE AIB REVERSEMENT DOCUMENT */
  async generateAibDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createAibSurveyDocument, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE CUSTOM TAX REVERSEMENT DOCUMENT */
  async generateCustomTaxDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createCustomTaxSurveyDocument, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PAYROLL REVERSEMENT BY ID */
  async getPayrollReversementById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPayrollReversementById, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE PAYROLL GROUP STATE */
  async updatePayrollGroupState(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.updatePayrollGroupState,
      data
    );
  },

  /* GENERATE PAYMENT SPLIT POINT RECEIPT DOCUMENT */
  async generatePaymentSplitPointReceiptDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createPaiementSplitPointReceipt, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE ADVANCE SURVEY YEARLY DOCUMENT */
  async generateAdvanceSurveyYearlyDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createAdvanceSurveyYearlyDocument, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET HOSPITALS CERTIFICATES */
  async getHospitalsCertificates(data) {
    return await http.get(
      apiRoutes.baseURL + apiRoutes.getHospitalCertificates,
      {
        params: data,
      }
    );
  },

  /* GENERATE CERTIFICATE SLIP DOCUMENT */
  async generateCertificateSlipDocument(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createCertificateSlip, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE HOSPITAL CERTIFICATE */
  async createHospitalCertificate(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createHospitalCertificate, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD NEW AGENT IN PAYROLL */
  async addNewAgentInPayroll(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.addNewAgentInPayroll, data)
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        // console.log(error);
        result.error = error;
      });
    return result;
  },

  /* UPDATE PAYROLL GROUP AGENT */
  async updatePayrollGroupAgent(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updatePayrollGroupAgent, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE GLOBAL BANK POINT CSV DOCUMENT */
  async generateGlobalBankPointCsvDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createGlobalBankPointCSV, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CLOSE LITIGATION */
  async closeLitigation(data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.closeLitigation, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE EXPENSES BUDGET DATA */
  async updateExpensesBudget(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateExpenseBudget, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE MISSION ADVANCE ETAT EXTERIOR DOCUMENT */
  async generateMissionAdvanceEtatExteriorDocument(id) {
    let result = null;
    await http
      .get(
        apiRoutes.baseURL + apiRoutes.createMissionAdvanceEtatExteriorDocument,
        {
          params: {
            id: id,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE EXTERN MISSION PAIEMENT RECEIPT DOCUMENT */
  async generateExternMissionPaiementReceiptDocument(id) {
    let result = null;
    await http
      .get(
        apiRoutes.baseURL +
          apiRoutes.createExternMissionPaiementReceiptDocument,
        {
          params: {
            id: id,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL PRIME CONFIG */
  async getPrimeConfig() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getConfigPrime)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE PRIME CONFIG */
  async createConfigPrime(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createConfigPrime, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE PRIME GROUP */
  async createPrimeGroup(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.createPrimeGroup, {
        name: data.name,
        date: data.date,
      })
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        console.log(error);
        result.error = error;
      });
    return result;
  },

  /* GET CURRENT PRIME GROUP */
  async getCurrentPrimeGroup() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getCurrentPrime)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL GROUP PRIMES */
  async getGroupPrimes(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getGroupPrime, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE PRIME */
  async updatePrime(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updatePrime, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD AGENT TO PRIME GROUP */
  async addAgentToPrimeGroup(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.addAgentPrimeGroup, data)
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        console.log(error);
        result.error = error;
      });
    return result;
  },

  /* REFRESH ALL PRIME GROUP */
  async refreshAllPrimeGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.refreshPrimeAgent, {
        group: data.id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CLOSE PRIME GROUP */
  async closePrimeGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.closePrimeGroup, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL PRIME GROUP */
  async getAllPrimeGroup(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllPrimeGroup, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET GROUPED GROUP PRIMES */
  async getGroupedGroupPrimes(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getGroupedPrimes, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET GROUP PRIME BY ID */
  async getGroupPrimeById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPrimeGroupById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CLOSE PRIME BY TYPE */
  async closePrimeByType(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.closePrimeByType, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE ONE MISSION */
  async updateOneMission(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateMission, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET AGENT BY ID */
  async getOneAgentById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAgentById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET AGENT BY ID */
  async getOneAgentByIdRh(id) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getAgentById(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* DELETE STRUCTURE BY ID */
  async deletedStructureById(id) {
    let result = null;
    await http
      .delete(apiRoutesRh.baseURL + apiRoutesRh.deletedStructureById(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD MULTIPLE DESTINATION TO EXTERIOR MISSION */
  async addMultipleDestinationMission(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.addMultipleDestinationMission, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE GROUP LITE PAYROLL */
  async createGroupLitePayroll(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.createGroupLitePayroll, data)
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        console.log(error);
        result.error = error;
      });
    return result;
  },

  /* GET CURRENT LITE PAYROLL GROUP */
  async getCurrentLitePayrollGroup() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getCurrentLiteGroup)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD AGENT TO LITE PAYROLL GROUP */
  async addAgentLitePayrollGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.addAgentLitePayroll, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* REFRESH LITE PAYROLL AGENT */
  async refreshLitePayrollGroupAgent(id) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.refreshLitePayroll, {
        id: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE AGENT LITE PAYROLL */
  async updateAgentLitePayroll(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateAgentLitePayroll, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL LITE PAYROLL GROUP */
  async getAllLitePayrollGroup(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllLitePayroll, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET LITE PAYROLL GROUP BY ID */
  async getLitePayrollGroupById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getLitePayrollById, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE LITE PAYROLL GROUP */
  async updateLitePayrollGroup(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateLitePayrollGroup, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE LITE PAYROLL GROUP POINT DOCUMENT */
  async generateLitePayrollGroupPointDocument(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createLitePayrollsGroupPoint, {
        params: id,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL SECTIONS BUDGET LINE */
  async getAllSectionBudgetLine(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getSection, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* DELETE ONE SUPPLIER */
  async deleteOneSupplier(data) {
    let result = null;
    await http
      .delete(apiRoutes.baseURL + apiRoutes.deleteSupplier, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET BUDGET LINE PAIEMENT SLIP */
  async getBudgetLinePaiementSlip(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getBudgetLinePaiementSlip, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE PRIME POINT BANK DOCUMENT */
  async generatePrimePointBankDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createPrimePointBankDocument, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE GLOBAL PRIME POINT DOCUMENT */
  async generateGlobalPrimePointDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createGlobalPrimePointDocument, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET PRIME AGENT MOVEMENT */
  async getPrimeAgentMovement(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPrimeAgentMovement, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE AGENT MOVING POINT DOCUMENT */
  async generateAgentMovingPointDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createAgentMovingPointDocument, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE MOVING LETTER DOCUMENT */
  async generateMovingLetterDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createMovingLetterDocument, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* RECEIPT MARKET */
  async receiptMarketAcceptance(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.receiptMarket, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ABORD MARKET */
  async receiptMarketAbort(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.abortMarket, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE EXPENSE INVOICE */
  async createExpenseInvoice(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.createExpenseInvoice,
      data
    );
  },
  /* GET ALL NEEDED */
  async getAllNeeded(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllNeeded, {
        params: data,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE NEEDED */
  async createNeeded(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.createNeeded, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE NEEDED STATE */
  async updateNeededState(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateNeededState, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET NEEDED BY ID */
  async getNeededById(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getNeededById, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL EXPENSE INVOICE */
  async getAllExpenseInvoice(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getAllExpenseInvoice, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL EXPENSE INVOICE BY ID */
  async getAllExpenseInvoiceById(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getExpenseInvoiceById, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* SUBMIT EXPENSE INVOICE */
  async submitExpenseInvoice(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.submitExpenseInvoice, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE BUDGET MANAGED BY REGIE */
  async validateBudgetManagedByRegie(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.validateBudgetManagedByRegie, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE CS BUDGET MANAGED BY REGIE */
  async validateCSBudgetManagedByRegie(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.validateCSBudgetManagedByRegie, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE DAF MANAGED BY REGIE */
  async validateDafManagedByRegie(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.validateDafManagedByRegie, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE REGIE MANAGED BY REGIE */
  async validateRegieManagedByRegie(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.validateRegieManagedByRegie, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE ASS REGIE MANAGED BY PUBLIC TREASURY */
  async validateAssRegieManagedByPublicTreasury(data) {
    let result = null;
    await http
      .post(
        apiRoutes.baseURL + apiRoutes.validateAssRegieManagedByPublicTreasury,
        data
      )
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE DAF MANAGED BY PUBLIC TREASURY */
  async validateDafManagedByPublicTreasury(data) {
    let result = null;
    await http
      .post(
        apiRoutes.baseURL + apiRoutes.validateDafManagedByPublicTreasury,
        data
      )
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* VALIDATE REGIE MANAGED BY PUBLIC TREASURY */
  async validateRegieManagedByPublicTreasury(data) {
    let result = null;
    await http
      .post(
        apiRoutes.baseURL + apiRoutes.validateRegieManagedByPublicTreasury,
        data
      )
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE INVOICE WALLET */
  async updateInvoiceWallet(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateInvoiceWallet, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* PAID INVOICE */
  async paidInvoice(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.paidInvoice, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* PAID INVOICE */
  async closeInvoice(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.closeInvoice, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE NEEDED */
  async updateNeeded(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateNeeded, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD COMMENT TO INVOICE */
  async addCommentInvoice(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.addCommentInvoice, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE ONE INVOICE */
  async updateInvoice(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateInvoice, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CHECK USER PERMISSION FOR DISPLAYING BUTTONS */
  async checkUserPermission(perm) {
    if (perm) {
      const user = store.getters.user;
      const permissions = user.permissions;
      let exist = false;

      if (permissions) {
        for (let i in permissions) {
          const iOf = permissions[i].toLowerCase().includes(perm.toLowerCase());
          console.log(iOf);
          exist = iOf;
        }
      }
      return exist;
    }
  },

  /* FULL REJECT EXPENSE INVOICE */
  async fullRejectExpenseInvoice(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.fullRejectInvoice, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL EXPENSE INVOICE */
  async filterExpenseInvoice(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.filterExpenseInvoice, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* UPDATE MARCHE */
  async updateMarket(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateMarket, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },
  /* ADD AGENT TO PRIME */
  async addAgentToPrime(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.addAgentToPrime, data)
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        console.log(error);
        result.error = error;
      });
    return result;
  },
  /* ADD AGENT TO PRIME */
  async removeAgentToPrime(data) {
    let result = {
      response: null,
      error: null,
    };
    await http
      .post(apiRoutes.baseURL + apiRoutes.removeAgentToPrime, data)
      .then((response) => {
        // console.log(response);
        result.response = response;
      })
      .catch((error) => {
        console.log(error);
        result.error = error;
      });
    return result;
  },

  /* UPDATE LITE PAYROLL */
  async updateLitePayroll(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.updateLitePayroll, data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* DELETE LITE PAYROLL ONE AGENT */
  async deleteLitePayrollOneAgent(data) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.deleteLitePayrollAgent, data)
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* DELETE ONE AGENT FROM PRIME LIST */
  async deleteAgentFromPrime(data) {
    let result = null;
    await http
      .delete(apiRoutes.baseURL + apiRoutes.deleteAgentFromPrime, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CANCEL PAIEMENT OR ADVANCE MISSION */
  async cancelPaiementOrAdvanceMission(id) {
    let result = null;
    await http
      .post(apiRoutes.baseURL + apiRoutes.cancelMission, {
        id: id,
      })
      .then((response) => {
        console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ALL AGENT IN PRIME */
  async getAllAgentPrime() {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.getPrimeAgents)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GET ONE RECRUITMENT BY ID */
  async getOneRecruitmentById(id) {
    let result = null;
    await http
      .get(apiRoutesRh.baseURL + apiRoutesRh.getRecruitmentById(id))
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* ADD DIRECTION TO RECRUITMENT */
  async addRecruitmentDirection(id, data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.addRecruitmentDirection(id), data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* CREATE RECRUITMENT NEED */
  async createRecruitmentNeed(id, data) {
    let result = null;
    await http
      .post(apiRoutesRh.baseURL + apiRoutesRh.createRecruitmentNeed(id), data)
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GEL ALL RECRUITMENT NEED */
  async getRecruitmentNeed(id, recruitmentId) {
    let result = null;
    await http
      .get(
        apiRoutesRh.baseURL + apiRoutesRh.getRecruitmentNeed(id, recruitmentId)
      )
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE RECRUITMENT BY DIRECTION DOCUMENT */
  async generateRecruitmentByDirectionDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createRecruitmentByDirectionDocument, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE RECRUITMENT BY CORPS DOCUMENT */
  async generateRecruitmentByCorpsDocument(data) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createRecruitmentByCorpsDocument, {
        params: data,
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* GENERATE INVOICE RECAP DOCUMENT */
  async generateInvoiceRecap(id) {
    let result = null;
    await http
      .get(apiRoutes.baseURL + apiRoutes.createInvoiceRecapDocument, {
        params: {
          id: id,
        },
      })
      .then((response) => {
        // console.log(response);
        result = response;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  },

  /* Preview mission group before creation */
  async getMissionGroupPreview(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.previewMissionGroup,
      data
    );
  },

  /* Delete one holiday if not finish */
  async deleteHoliday(data) {
    return await http.post(apiRoutes.baseURL + apiRoutes.deleteHoliday, data);
  },

  /* Get one mission by id */
  async getOneMissionById(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.getMissionById, {
      params: data,
    });
  },

  /* Get one mission group by id */
  async getOneMissionGroupById(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.getMissionGroupById, {
      params: data,
    });
  },

  /* Allow access to adjust holiday */
  async allowHolidayAccess(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.allowAccessHoliday,
      data
    );
  },

  /* Remove access to adjust holiday */
  async removeHolidayAccess(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.removeAccessHoliday,
      data
    );
  },

  /* Export agents statistics */
  async exportAgentStatistics(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.exportAgentStats,
      data
    );
  },

  /* Get structure by id */
  async getStructureByIdDaf(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.structureById, {
      params: data,
    });
  },

  /* Get structure agent by id */
  async getStructureAgentByIdDaf(data) {
    return await http.get(apiRoutes.baseURL + apiRoutes.getStructureAgentById, {
      params: data,
    });
  },

  /* Get holiday stats */
  async getHolidayStats() {
    return await http.post(apiRoutes.baseURL + apiRoutes.holidayStat);
  },

  /* Export insurance stats */
  async exportInsuranceAgentContract(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.exportContractAgent,
      data
    );
  },

  /* Get agent stats */
  async getAgentStatsDashboard() {
    return await http.post(apiRoutes.baseURL + apiRoutes.getAgentStats);
  },

  /* Update wallet funding */
  async updateWalletFunding(data) {
    return await http.post(
      apiRoutes.baseURL + apiRoutes.update_wallet_funding,
      data
    );
  },
};
export default allFunctions;
