export default {
  baseURL:
    process.env.VUE_APP_DAF_BASE_URL ||
    "https://gouv-daf-v2.41devs.com/api/v1/daf/",
  getBudgets: "budget/get-budgets",
  getBudgetStats: "budget/get-budget-stats",
  getBudgetById: "budget/get-budget-by-id-or-year",
  createBudget: "budget/create-budget",
  updateMajor: "majors/update-major",
  updateGc: "gc/update-gc",
  createAction: "actions/create-action",
  getAction: "actions/get-budget-actions",
  createActivity: "activity/create-activity",
  getActivity: "activity/get-actions-activity",
  createBudgetLine: "budget-line/create-budget-line",
  validBudget: "budget/validate-budget",
  createSupplier: "supplier/create",
  getSuppliers: "supplier/get-suppliers",
  getBudgetActivity: "activity/get-budget-activities",
  getUniteActivity: "pta-activity/get-pta-activity-of-ua",
  createActivityPta: "pta-activity/create-pta-activity",
  createPtaTask: "task/create-task",
  createPaiement: "paiement-slip/create-paiement-slip",
  getPaiement: "paiement-slip/get-budget-paiement-slip",
  getMajorForBudget: "majors/get-budget-majors",
  getBudgetLine: "budget-line/get-activity-budget-lines",
  updateBudgetLine: "budget-line/update-budget-line",
  getTaskLine: "task/get-pta-activity-task",
  updateTask: "task/update-task",
  paiementExpense: "paiement-slip/get-paiement-slip-by-id",
  estimate: "mission/estimate",
  getBudgetLineByBId: "market/get-budget-lines-or-paiement-slips",
  createMission: "mission/create-mission",
  allMission: "mission/get-all-mission",
  allMissionGroup: "mission/get-all-mission-group",
  createMissionGroup: "mission/create-mission-group",
  createMarket: "market/create",
  addMarketFile: "market/add-markets-files",
  getSupplierById: "supplier/get-by-id",
  getBudgetLineOrPaymentSlip: "market/get-budget-lines-or-paiement-slips",
  getAllMarket: "market/get-all",
  getMarketById: "market/get-by-id",
  createExpense: "expenses/create-expenses",
  getAllExpenses: "expenses/get-all-expenses",
  getExpenseById: "expenses/get-expense-by-id",
  paisExpense: "paiement/paid-expense",
  getOneBudgetLine: "budget-line/get-budget-line-by-id",
  createGroup: "mission/create-mission-group",
  getGc: "gc/get-all-gc",
  missionPayement: "mission/get-all-mission-for-paiement",
  missionAvance: "mission/get-all-mission-for-advance",
  getAllMission: "mission/get-all-mission",
  getAllMissionGroup: "mission/get-all-mission-group",
  getPaymentStats: "stats/paiement-stats",
  getExpenseStats: "stats/expenses-stats",
  closeMissionGroup: "mission/close-mission-group",
  recapBudget: "budget/get-budget-all-relations",
  getMajorAndLine: "budget/get-budget-all-majors-budget-lines",
  updateAction: "actions/update-action",
  deleteAction: "actions/delete-action",
  currentPayroll: "payroll/get-current-payroll",
  createPayroll: "payroll/create-payroll-group",
  updatePayroll: "payroll/update-payroll",
  validePayRoll: "payroll/payroll-validate",
  submitPayRoll: "payroll/submited-payroll-group",
  deleteBudgetLine: "budget-line/delete-budget-line-by-id",
  createWallet: "wallet/create-wallet",
  getAllWallet: "wallet/get-all-wallet",
  updateExpenseState: "expenses/update-expenses-state",
  paidMission: "paiement/paid-mission",
  getWalletById: "wallet/get-wallet-by-id",
  createFundWallet: "wallet/fund-wallet",
  getWalletFundingById: "wallet/get-wallet-funding-by-id",
  createSurvey: "wallet/create-survey",
  createRecipient: "wallet/create-recipient",
  getAllRecipients: "wallet/get-all-recipients",
  updateRecipient: "wallet/update-recipient",
  createInvoice: "wallet/create-invoice",
  getFundingWalletInvoice: "wallet/get-funding-wallet-invoices",
  getSurvey: "wallet/get-survey",
  updateSupplier: "supplier/update",
  getDafStats: "stats/daf-stats",
  getPayrollGroup: "payroll/get-payroll-group",
  getPayrollGroupById: "payroll/get-payroll-group-by-id",
  paidPayroll: "payroll/paid-payroll",
  getAllAgents: "payroll/get-all-agents",
  closePayrollGroup: "payroll/close-payroll-group",
  getPayrollStats: "stats/payrolls-stats",
  getAllHospitalCenters: "receipts/get-all-hospital-centers",
  getHospitalCentersById: "receipts/get-hospital-receipts",
  createHospitalCenter: "receipts/create-hospital-center",
  createReceipt: "receipts/create-receipt",
  getAllCotisations: "subscriptionsn/get-subscriptions-list",
  getAgentCotisations: "subscriptionsn/get-agent-subscriptions",
  getAllInsuranceFunds: "insurance/get-all-insurance-funds",
  getAllInsurance: "insurance/get-all",
  createInsuranceFund: "insurance/create-insurance-funds",
  updateCotisationState: "subscriptionsn/update-payroll-cnss-state",
  configurationPayroll: "payroll/updade-payroll-group-by-criteria",
  readyForClose: "payroll/get-payroll-group-ready-for-close",
  payrollGroupByType: "payroll/get-payroll-group-by-type",
  getPayrollReversement: "payroll/get-payroll-ready-for-reversement",
  updateTaxeReversment: "payroll/update-tax-reversement-state",
  getInsuranceFundById: "insurance/get-insurance-funds-by-id",
  addAgentInFund: "insurance/add-agent-in-fund",
  getAllCare: "care/get-all-care",
  createCare: "care/create-care",
  updateCare: "care/update-care",
  getActivityById: "activity/get-activity-by-id",
  duplicate: "budget/create-budget-with-data",
  getSection: "section/get-sections",
  updateActivity: "activity/update-activity",
  createPaymentReceiptDocument: "daf-documents/create-paiement-receipt",
  createMissionAdvanceEtatDocument: "daf-documents/create-mission-advance-etat",
  createMissionPaymentReceiptDocument:
    "daf-documents/create-mission-paiement-receipt",
  createAdvanceSurveyDocument: "daf-documents/create-advance-survey",
  createExternMissionPaymentReceipt:
    "daf-documents/externe-mission-paiement-receipt",
  createPayrollGroupPaymentPoint: "daf-documents/payroll-group-paiement-point",
  createBankPayrollPoint: "daf-documents/bank-payroll-point",
  createInsurance: "insurance/create",
  calculateItsCnss: "payroll/calculate-its-cnss",
  getAllPayrollTaxConfig: "payroll/get-payroll-tax-config",
  deleteAgentPayroll: "payroll/delete-payroll",
  cancelPayrollGroup: "payroll/cancel-payroll-group",
  updatePayrollAgent: "payroll/update-payroll-agent",
  createGlobalBankPoint: "daf-documents/global-bank-point",
  createGlobalStructurePoint: "daf-documents/global-struct-point",
  getPayrollBankFile: "payroll/get-payroll-bank-files",
  setPayrollBankFile: "payroll/set-payroll-bank-file",
  deleteActivity: "activity/delete-activity",
  deleteTask: "task/delete-task",
  getAllTaxes: "payroll/get-all-taxes",
  createCnssSurveyDocument: "daf-documents/cnss-survey",
  createItsSurveyDocument: "daf-documents/its-survey",
  createAibSurveyDocument: "daf-documents/aib-survey",
  createCustomTaxSurveyDocument: "daf-documents/custom-tax-survey",
  getPayrollReversementById: "payroll/get-payroll-ready-for-reversement-by-id",
  updatePayrollGroupState: "payroll/update-payroll-group-state",
  getAllOtherlTax: "payroll/get-all-taxes",
  createOtherTax: "payroll/create-tax",
  createPaiementSplitPointReceipt: "daf-documents/paiement-split-point-receipt",
  createAdvanceSurveyYearlyDocument:
    "daf-documents/create-advance-survey-yearly",
  getHospitalCertificates: "receipts/get-hospital-certificates",
  createCertificateSlip: "receipts/generate-certificate-slip",
  createHospitalCertificate: "receipts/generate-certificate",
  addNewAgentInPayroll: "payroll/add-new-agent-in-payroll",
  updatePayrollGroupAgent: "payroll/update-payroll-group-agent",
  createGlobalBankPointCSV: "daf-documents/global-bank-point-csv",
  updateExpenseBudget: "expenses/update-expenses-budget",
  createMissionAdvanceEtatExteriorDocument:
    "daf-documents/create-mission-advance-etat-exterior",
  createExternMissionPaiementReceiptDocument:
    "daf-documents/externe-mission-paiement-receipt",
  budgetLineByBudgetId: "budget/get-budget-paiement-slip-and-budget-line",
  updatePaiementSlip: "paiement-slip/update-paiement-slip",
  updateActivityPta: "pta-activity/update-pta-activity",
  getConfigPrime: "prime/get-config",
  createConfigPrime: "prime/config",
  createPrimeGroup: "prime/create-prime-group",
  getCurrentPrime: "prime/get-current-prime-group",
  getGroupPrime: "prime/get-group-primes",
  updatePrime: "prime/update-prime",
  addAgentPrimeGroup: "prime/add-agent-to-prime-group",
  refreshPrimeAgent: "prime/refresh-all-prime-agent",
  closePrimeGroup: "prime/close-prime-group",
  getAllPrimeGroup: "prime/get-all-prime-group",
  getGroupedPrimes: "prime/get-grouped-group-prime",
  getPrimeGroupById: "prime/get-prime-group-by-id",
  closePrimeByType: "prime/close-prime-by-type",
  updateMission: "mission/update-mission",
  getAgentById: "prime/get-agent-by-id",
  addMultipleDestinationMission: "mission/add-destination-to-mission",
  createGroupLitePayroll: "lite-payroll/create-group",
  getCurrentLiteGroup: "lite-payroll/current-group",
  addAgentLitePayroll: "lite-payroll/add-agent",
  refreshLitePayroll: "lite-payroll/update-payroll-group-agent-data",
  updateAgentLitePayroll: "lite-payroll/update-lite-payroll",
  getAllLitePayroll: "lite-payroll/all-group",
  getLitePayrollById: "lite-payroll/group-by-id",
  updateLitePayrollGroup: "lite-payroll/update-lite-payrol",
  createLitePayrollsGroupPoint: "daf-documents/lite-payrolls-group-point",
  budgetLineOfYear: "budget-line/get-year-budget-line",
  uaRepartition: "budget-stats/get-budget-repartition-by-action",
  typeRepartition: "budget-stats/get-action-repartition-by-type",
  natureRepartition: "budget-stats/get-budget-repartition-by-nature",
  gcRepartition: "budget-stats/get-budget-gc-repartition",
  deleteSupplier: "supplier/delete",
  getBudgetLinePaiementSlip: "budget-line/get-budget-line-paiement-slip",
  createPrimePointBankDocument: "daf-documents/prime-point-bank",
  createGlobalPrimePointDocument: "daf-documents/global-prime-point",
  getPrimeAgentMovement: "prime/get-prime-agent-moov",
  createAgentMovingPointDocument: "daf-documents/agent-moving-point",
  createMovingLetterDocument: "daf-documents/moving-letter",
  receiptMarket: "market/market-acceptance-state",
  abortMarket: "market/aborted-market",
  createExpenseInvoice: "invoices/create-expense-invoice",
  getAllExpenseInvoice: "invoices/get-all-expense-invoice",
  getExpenseInvoiceById: "invoices/get-expense-invoice-by-id",
  submitExpenseInvoice: "invoices/submit-expense-invoice",
  getAllNeeded: "needed/get-all-needed",
  getNeededById: "needed/get-needed-by-id",
  createNeeded: "needed/create-needed",
  updateNeededState: "needed/update-needed-state",
  validateBudgetManagedByRegie: "invoices/validate-in-budget-regie-managed",
  validateCSBudgetManagedByRegie:
    "invoices/validate-in-cs_budget-regie-managed",
  validateDafManagedByRegie: "invoices/validate-daf-regie-managed",
  validateRegieManagedByRegie: "invoices/validate-in-regie-regie-managed",
  validateAssRegieManagedByPublicTreasury: "invoices/validate-in-ass_regie",
  validateDafManagedByPublicTreasury: "invoices/validate-in-daf",
  validateRegieManagedByPublicTreasury: "invoices/validate-in-regie",
  updateInvoiceWallet: "wallet/update-invoice",
  paidInvoice: "invoices/paid-invoice",
  closeInvoice: "invoices/close-invoice",
  updateNeeded: "needed/update-needed",
  addCommentInvoice: "invoices/add-comment",
  updateInvoice: "invoices/update-expense-invoice",
  fullRejectInvoice: "invoices/full-reject-expense-invoice",
  filterExpenseInvoice: "invoices/filter-expense-invoice",
  updateMarket: "market/update-market",
  addAgentToPrime: "prime/add-agent-to-prime-mouv",
  removeAgentToPrime: "prime/remove-add-agent-to-prime-mouv",
  updateLitePayroll: "lite-payroll/update-lite-payrol",
  deleteLitePayrollAgent: "lite-payroll/delete-payroll",
  deleteAgentFromPrime: "prime/delete-agent-from-prime",
  cancelMission: "mission/cancel-mission",
  getPrimeAgents: "prime/get-agents",
  createRecruitmentByDirectionDocument:
    "daf-documents/recruitment-point-by-direction",
  createRecruitmentByCorpsDocument: "daf-documents/recruitment-point-by-corp",
  getHolidays: "holidays/get-holidays",
  createHollidays: "holidays/create-holidays",
  recapHolidays: "holidays/get-holiday-recap",
  createPreviousHolidays: "holidays/previous",
  allHolliday: "holidays/get-all-holidays",
  createInvoiceRecapDocument: "daf-documents/invoice-recap",
  saveSpecialDay: "holidays/create-special-public-holiday",
  getSpecialHoliday: "holidays/get-public-special-holiday",
  getHolidayType: "holidays/get-holiday-type",
  estimateHolidays: "holidays/estimate-holiday-detail",
  createHoliday: "holidays/create-holidays",
  previewMissionGroup: "mission/get-mission-group-preview",
  desableHoliday: "holidays/disable-holiday-day",
  deleteHoliday: "holidays/delete-holiday",
  structureGetter: "core/structure",
  getMissionById: "mission/get-mission-by-id",
  getMissionGroupById: "mission/get-mission-group-by-id",
  allowAccessHoliday: "holidays/allowed-holiday-agent",
  removeAccessHoliday: "holidays/remove-allowed-holiday-agent",
  exportAgentStats: "agent-stats/exportation-stats",
  structureById: "core/structure",
  getStructureAgentById: "core/get-agent-by-struc-id",
  holidayStat: "agent-stats/holiday-stats",
  exportContractAgent: "insurance-stats/export-contract-agents",
  getAgentStats: "agent-stats/agent-lite-stats",
  update_wallet_funding: "wallet/update-wallet-funding",
};
